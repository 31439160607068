import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  body {
    background: url("/images/bg.png") center center / cover no-repeat fixed;

    img {
      height: auto;
      max-width: 100%;
    }
  }
`

export default GlobalStyle
